import { SmallPersonListItem } from "./people/SmallPersonListItem"

export const RegularList = ({
	items,
	resourceName,
	itemComponent: ItemComponent,
}) => {
	return (

		<SmallPersonListItem />
		// <>
		// {items.map((item, i) => (

			// name={item.name}
			// age={item.age}
			// />
			// <SmallPersonListItem key={i} {...{ [resourceName]: item }} />
		// ))}
	//  </>
	)
}